<template>
  <div class="main-content">
    <div class="main-title">管理员编辑</div>
    <div class="from">
      <div class="row">
        <div class="title">账户名称</div>
        <input type="text" placeholder="请输入账户名称" v-model.trim="username">
      </div>
      <div class="row">
        <div class="title">账户密码</div>
        <input type="text" placeholder="请输入新的账户密码" v-model.trim="password">
      </div>
      <div class="row">
        <div class="title">所属分组</div>
        <el-select v-model="agid" filterable remote placeholder="请选择" :remote-method="searchGroup" >
          <el-option
              v-for="item in arrGroup"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="btn-box">
        <span class="btn-confirm" @click="editUser">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getGroupList,editAdmin,getAdminInfo} from "@/request/api";
import {Warning} from "@/plugins/warning";
import md5 from "js-md5";

export default {
  name: "sys_admin",
  components:{

  },
  data() {
    return {
      id:0,
      username:"",
      password:"",
      agid:"",
      arrGroup:[]
    }
  },
  computed: {

  },
  created() {
    this.id = this.$route.query.id;
    this.getGroupList();
    if(this.id > 0){
      this.getAdminInfo();
    }else{
      this.id = 0;
    }
  },
  mounted() {

  },
  methods: {
    editUser(){
      const requestData = {
        "id":this.id,
        "username":this.username,
        "password":md5(this.password),
        "agid":this.agid,
      };
      editAdmin(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
    getAdminInfo(){
      const requestData = {"id":this.id};
      getAdminInfo(requestData).then((res)=>{
        this.username = res.data.username;
        this.agid = res.data.agid;
      })
    },
    getGroupList(){
      const requestData = {
        page: 0,
      };
      getGroupList(requestData).then((res)=>{
        this.arrGroup = res.data.list;
      })
    },
    searchGroup(val){
      console.log(val)
      const requestData = {
        page: 0,
        keyword:val
      };
      getGroupList(requestData).then((res)=>{
        this.arrGroup = res.data.list;
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 300px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>

<style>
.el-input__inner{
  border: 0;
}
</style>
